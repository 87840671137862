import * as PropTypes from 'prop-types'
import React, {useState, useEffect, memo} from 'react'
import {equals} from 'ramda'
import {
  ButtonPrimary,
  LogoutIcon,
  HomeIcon,
  GlobeEuropeIcon,
  BillingIcon,
  AddIcon,
  CustomerPageLayout,
  GlobeIcon,
  Caption,
} from '@modifi/ui'
import {clientConfig} from '@modifi/client-config'
import {white} from '@modifi/colors'
import {ContentContainer} from './styles'
import {useAbility, asMainNavigationSubject} from '../../constants/permissions'
import {
  NAVIGATION_SHIPMENT_TRACKER,
  NAVIGATION_ONBOARDING,
  NAVIGATION_BILLING,
  NAVIGATION_ORDERS,
  NAVIGATION_OVERVIEW,
} from '../../constants/navigation'

import {withUserAccess} from '../../hoc'
import {CsvFileIcon, CompanyIcon} from '../../ui/icons'

const ContentContainerWithUserAccess = withUserAccess(ContentContainer)

const PageLayout = ({
  children,
  userFirstName,
  userLastName,
  userEmail,
  companyName,
  t,
  isOrderCreationDisabled,
  hasTradePartnerOrders,
  hasCreatedTrades,
  userActions,
  userIsBFOnly,
}) => {
  const ability = useAbility()

  const [newFeatureTooltip, setNewFeatureTooltip] = useState(false)

  const isDirectCustomer = userActions?.canCreateOrder

  const logoItem = {
    to: '/dashboard',
  }

  const showOverview = ability.can('read', asMainNavigationSubject(NAVIGATION_OVERVIEW))
  const showOnboarding = ability.can('read', asMainNavigationSubject(NAVIGATION_ONBOARDING))
  const showBilling = ability.can('read', asMainNavigationSubject(NAVIGATION_BILLING))
  const showOrders = ability.can('read', asMainNavigationSubject(NAVIGATION_ORDERS))
  const showShipmentTracker = ability.can(
    'read',
    asMainNavigationSubject(NAVIGATION_SHIPMENT_TRACKER)
  )

  useEffect(() => {
    let timeout
    if (showOnboarding && !newFeatureTooltip) {
      timeout = setTimeout(() => {
        setNewFeatureTooltip(true)
      }, 3000)
    }
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CustomerPageLayout
      data-ident="page-layout"
      data-current-user={userEmail}
      // allowMenuCollapse // @todo allow menu collapse after resize event emit is implemented
      logoItem={logoItem}
      menuItems={[
        showOverview && !userIsBFOnly
          ? {
              label: t('layout.side-navigation.dashboard.label'),
              to: '/dashboard',
              icon: HomeIcon,
              'data-ident': 'dashboard-link',
            }
          : null,
        showOrders && {
          label: t('layout.side-navigation.orders.label'),
          to: isDirectCustomer ? '/orders' : '/trade-partner-orders',
          icon: GlobeEuropeIcon,
          'data-ident': 'orders-link',
          subMenus:
            isDirectCustomer && hasTradePartnerOrders
              ? [
                  {
                    label: t('layout.side-navigation.direct-party-orders.label'),
                    to: '/orders',
                  },
                  {
                    label: t('layout.side-navigation.counter-party-orders.label'),
                    to: '/trade-partner-orders',
                  },
                ]
              : null,
        },
        isDirectCustomer && showBilling && clientConfig.get('service_billing')
          ? {
              label: t('layout.side-navigation.billing.label'),
              to: clientConfig.get('service_billing'),
              isExternal: true,
              icon: BillingIcon,
              'data-ident': 'billing-link',
            }
          : null,
        isDirectCustomer && hasCreatedTrades
          ? {
              label: t('layout.side-navigation.statement.label'),
              to: '/account-statement',
              icon: CsvFileIcon,
              'data-ident': 'statement-link',
            }
          : null,
        clientConfig.get('service_shipments', false) !== false && showShipmentTracker
          ? {
              label: t('layout.side-navigation.services.label'),
              to: clientConfig.get('service_shipments'),
              isExternal: true,
              icon: GlobeIcon,
              'data-ident': 'services-link',
              subMenus: [
                {
                  label: t('layout.side-navigation.shipments.label'),
                  to: clientConfig.get('service_shipments'),
                  isExternal: true,
                  'data-ident': 'shipment-services-link',
                },
              ],
            }
          : null,
      ].filter(Boolean)}
      ctaItem={
        isDirectCustomer
          ? {
              label: (
                <ButtonPrimary big data-target="create-order" active={!isOrderCreationDisabled}>
                  {t('layout.top-navigation.cta-create-order')}
                </ButtonPrimary>
              ),
              to: '/create-order',
              hasButton: true,
              isDisabled: isOrderCreationDisabled,
            }
          : null
      }
      linkItem={
        isDirectCustomer
          ? {
              label: t('layout.side-navigation.cta-create-order'),
              to: '/create-order',
              icon: AddIcon,
              'data-ident': 'create-trade-link',
            }
          : null
      }
      userDropdown={{
        showNewFeatureTooltip: newFeatureTooltip,
        newFeatureTooltipHeadline: t('user-dropdown.new-feature-tooltip.headline'),
        newFeatureTooltipContent: (
          <Caption color={white}>{t('user-dropdown.new-feature-tooltip.content')}</Caption>
        ),
        firstName: userFirstName,
        lastName: userLastName,
        companyName,
        items: [
          clientConfig.get('service_onboarding', false) !== false && showOnboarding
            ? {
                label: t('layout.side-navigation.company-details.label'),
                to: `${clientConfig.get('service_onboarding')}?navigationSource=hub`,
                icon: CompanyIcon,
                'data-ident': 'onboarding-link',
              }
            : null,
          {
            label: t('layout.top-navigation.logout.label'),
            icon: LogoutIcon,
            to: '/logout',
          },
        ].filter(Boolean),
      }}
      disableNotifications
    >
      <ContentContainerWithUserAccess>{children}</ContentContainerWithUserAccess>
    </CustomerPageLayout>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  companyName: PropTypes.string,
  userEmail: PropTypes.string,
  t: PropTypes.func.isRequired,
  isOrderCreationDisabled: PropTypes.bool.isRequired,
  hasTradePartnerOrders: PropTypes.bool.isRequired,
  hasCreatedTrades: PropTypes.bool.isRequired,
  userActions: PropTypes.shape({
    canCreateOrder: PropTypes.bool.isRequired,
  }).isRequired,
  userIsBFOnly: PropTypes.bool.isRequired,
}

PageLayout.defaultProps = {
  userFirstName: '',
  userLastName: '',
  companyName: '',
  userEmail: '',
}

export default memo(PageLayout, equals)
