import '@modifi/plugin-authentication/register'
import '@modifi/plugin-countries/register'
import '@modifi/plugin-dialogs/register'
import '@modifi/plugin-google-tag-manager/register'
import '@modifi/plugin-notifications/register'
import '@modifi/plugin-router-history/register'
import '@modifi/plugin-user/register'
import '@modifi/plugin-snackbars/register'
import '@modifi/plugin-documents/register'
import './custom-plugins/order-filters/register'
import './custom-plugins/shipment-tracking/register'
import './custom-plugins/trade-partner-order-filters/register'
import {init as i18nInit} from '@modifi/plugin-i18n/register'

export const initPlugins = (options = {}) => Promise.all([i18nInit(options?.i18n)])
