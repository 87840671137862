import React, {useCallback, useEffect} from 'react'
import * as PropTypes from 'prop-types'
import {Form, FormInput as FormInputWrapper, SubmitBehavior} from '@modifi/plugin-forms'
import {Col, DangerAlert, H4 as Headline, Row, TextAreaPure, withInputContainer} from '@modifi/ui'
import {useTranslation} from '@modifi/plugin-i18n'
import {InferPropTypes} from '@modifi/typescript-utils'
import {SkeletonLine} from '../../ui/skeleton-loaders'
import {CustomActionFooter} from './components/custom-action-footer'
import {StyledComment} from './styles'

const TextAreaPureWithInputContainer = withInputContainer(TextAreaPure)

const propTypes = {
  params: PropTypes.shape({
    externalOrderId: PropTypes.string,
    isOrdersPage: PropTypes.bool,
  }).isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  isRequestPending: PropTypes.bool.isRequired,
  isUpdatePending: PropTypes.bool.isRequired,
  modifiComment: PropTypes.string,
  buyerCommentRequired: PropTypes.bool,
  submit: PropTypes.func.isRequired,
  cleanUpUpdate: PropTypes.func.isRequired,
  cleanUpFetch: PropTypes.func.isRequired,
  fetchTradeConfirmation: PropTypes.func.isRequired,
  ensureTradeConfirmation: PropTypes.func.isRequired,
  errorCode: PropTypes.number,
  closeDialog: PropTypes.func.isRequired,
  fetchOrder: PropTypes.func.isRequired,
  fetchOrders: PropTypes.func.isRequired,
  fetchOrdersMeta: PropTypes.func.isRequired,
}
const defaultProps = {
  buyerCommentRequired: false,
  hasError: false,
  modifiComment: null,
  errorMessage: undefined,
  errorCode: undefined,
}

export type TradeConfirmationProps = InferPropTypes<typeof propTypes, typeof defaultProps>

const customErrorMessage = (
  t: (...args: any[]) => string,
  errorMessage?: string,
  errorCode?: number
) => {
  switch (errorCode) {
    case 403:
      return t('dialog.buyer-confirmation.unauthorised-error-message')
    default:
      return errorMessage || t('dialog.buyer-confirmation.internal-error')
  }
}

const BuyerConfirmation: React.FC<TradeConfirmationProps> = ({
  params,
  submit,
  cleanUpUpdate,
  cleanUpFetch,
  fetchTradeConfirmation,
  ensureTradeConfirmation,
  isRequestPending,
  isUpdatePending,
  modifiComment,
  buyerCommentRequired,
  errorMessage,
  hasError,
  errorCode,
  closeDialog,
  fetchOrder,
  fetchOrders,
  fetchOrdersMeta,
}) => {
  const {externalOrderId, isOrdersPage} = params

  const {t} = useTranslation('matrix')

  useEffect(() => {
    ensureTradeConfirmation(externalOrderId)
    return () => {
      if (isOrdersPage) {
        cleanUpFetch()
      }
      cleanUpUpdate()
    }
  }, [ensureTradeConfirmation, externalOrderId, cleanUpUpdate, cleanUpFetch, isOrdersPage])

  const minLengthMessage = useCallback(
    ({minLength}: {minLength: string}) =>
      t('dialog.buyer-confirmation-note.comment-min-length.warning', {minLength}),
    [t]
  )
  const maxLengthMessage = useCallback(
    ({maxLength}: {maxLength: string}) =>
      t('dialog.buyer-confirmation-note.comment-max-length.warning', {maxLength}),
    [t]
  )

  const handleSubmit = useCallback(
    async (changes: Record<string, any>) => {
      try {
        await submit(changes)
        if (isOrdersPage) {
          fetchOrdersMeta()
          fetchOrders()
        } else {
          fetchOrder(externalOrderId)
          fetchTradeConfirmation(externalOrderId)
        }
        // close the dialog
        closeDialog()
      } catch (err) {
        // do nothing
      }
    },
    [
      submit,
      isOrdersPage,
      closeDialog,
      fetchOrdersMeta,
      fetchOrders,
      fetchOrder,
      externalOrderId,
      fetchTradeConfirmation,
    ]
  )

  return (
    <Form
      initialValues={{comment: ''}}
      submitBehavior={SubmitBehavior.SUBMIT_DISABLED_UNLESS_VALID}
    >
      {hasError && (
        <Row mb={16}>
          <Col>
            <DangerAlert>{customErrorMessage(t, errorMessage, errorCode)}</DangerAlert>
          </Col>
        </Row>
      )}
      <Row mb={40}>
        <Col>
          <Headline>{t(`dialog.buyer-confirmation-note.headline`)}</Headline>
        </Col>
        <Col col={12}>
          {isRequestPending ? (
            <SkeletonLine stringLength={50} />
          ) : (
            <StyledComment>{modifiComment || '-'}</StyledComment>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {isRequestPending ? (
            <SkeletonLine stringLength={50} />
          ) : (
            <FormInputWrapper
              name="comment"
              label={t(
                `dialog.buyer-confirmation-note.user-comment.${
                  buyerCommentRequired ? 'required' : 'optional'
                }.label`
              )}
              inputComponent={TextAreaPureWithInputContainer}
              t={t}
              rows={5}
              minLength={3}
              minLengthMessage={minLengthMessage}
              maxLength={500}
              maxLengthMessage={maxLengthMessage}
              optional={!buyerCommentRequired}
            />
          )}
        </Col>
      </Row>
      <CustomActionFooter
        externalOrderId={externalOrderId}
        onSubmit={handleSubmit}
        loading={isRequestPending || isUpdatePending}
      />
    </Form>
  )
}

BuyerConfirmation.propTypes = propTypes
BuyerConfirmation.defaultProps = defaultProps

export default BuyerConfirmation
