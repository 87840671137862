import React from 'react'
import {AsyncFeature, AsyncComponent} from '@modifi/code-splitting'
import PageContentLoader from '../ui/page-content-loader'

const loader = () => <PageContentLoader />

export default [
  {
    path: '/',
    redirectTo: '/dashboard',
  },
  {
    path: '/dashboard',
    component: AsyncFeature({
      loader,
      importThunk: () => import(/* webpackChunkName:"dashboard" */ '../pages/dashboard'),
    }),
  },
  {
    path: '/orders',
    component: AsyncFeature({
      loader,
      importThunk: () => import(/* webpackChunkName:"orders" */ '../pages/orders'),
    }),
  },
  // migrating to order filters
  {
    path: '/orders/in-progress',
    redirectTo: '/orders',
  },
  {
    path: '/orders/completed',
    redirectTo: '/orders',
  },
  {
    path: '/orders-filter',
    component: AsyncFeature({
      loader,
      importThunk: () => import(/* webpackChunkName:"orders" */ '../pages/orders'),
    }),
    layout: AsyncComponent({
      loader,
      importThunk: () => import(/* webpackChunkName:"orders" */ '../pages/orders/layouts'),
    }),
  },
  {
    path: '/trade-partner-orders',
    component: AsyncFeature({
      loader,
      importThunk: () =>
        import(/* webpackChunkName:"trade-partner-orders" */ '../pages/trade-partner-orders'),
    }),
  },
  {
    path: '/trade-partner-orders-filter',
    component: AsyncFeature({
      loader,
      importThunk: () =>
        import(/* webpackChunkName:"trade-partner-orders" */ '../pages/trade-partner-orders'),
    }),
    layout: AsyncComponent({
      loader,
      importThunk: () =>
        import(
          /* webpackChunkName:"trade-partner-orders" */ '../pages/trade-partner-orders/layouts'
        ),
    }),
  },
  {
    path: '/create-order',
    component: AsyncFeature({
      loader,
      importThunk: () => import(/* webpackChunkName:"create-order" */ '../pages/create-order'),
    }),
    layout: AsyncComponent({
      loader,
      importThunk: () =>
        import(/* webpackChunkName:"create-order" */ '../pages/create-order/layouts'),
    }),
  },
  {
    path: '/orders/:orderId',
    component: AsyncFeature({
      loader,
      importThunk: () => import(/* webpackChunkName:"order-details" */ '../pages/order'),
    }),
  },
  {
    path: '/orders/:orderId/payments/:paymentId?',
    component: AsyncFeature({
      loader,
      importThunk: () => import(/* webpackChunkName:"order-details" */ '../pages/order'),
    }),
  },
  {
    path: '/orders/:orderId/documents/:paymentId?',
    component: AsyncFeature({
      loader,
      importThunk: () => import(/* webpackChunkName:"order-details" */ '../pages/order'),
    }),
    componentProps: {
      activeTab: 'DOCUMENTS',
    },
  },
  {
    path: '/trade-partner-orders/:orderId',
    component: AsyncFeature({
      loader,
      importThunk: () =>
        import(/* webpackChunkName:"trade-partner-order-details" */ '../pages/trade-partner-order'),
    }),
  },
  {
    path: '/trade-partner-orders/:orderId/documents',
    component: AsyncFeature({
      loader,
      importThunk: () =>
        import(/* webpackChunkName:"trade-partner-order-details" */ '../pages/trade-partner-order'),
    }),
    componentProps: {
      activeTab: 'DOCUMENTS',
    },
  },
  {
    path: '/account-statement',
    component: AsyncFeature({
      loader,
      importThunk: () => import(/* webpackChunkName:"dashboard" */ '../pages/statement'),
    }),
  },
]
