import {connect} from '@modifi/redux'
import {compose} from 'ramda'
import {withTranslation} from '@modifi/plugin-i18n'

import {
  selectFirstName,
  selectCompanyName,
  selectLastName,
  selectUserEmail,
  selectUserActions,
  selectHasTradePartnerOrders,
  selectHasCreatedTrades,
  selectUserHasBFOnly,
} from '../../modules/user'
import PageLayout from './PageLayout'
import {selectIsOrderCreationDisabled} from '../../modules/create-order-limitations/selectors'

const mapStateToProps = state => ({
  userFirstName: selectFirstName(state),
  userLastName: selectLastName(state),
  userEmail: selectUserEmail(state),
  companyName: selectCompanyName(state),
  isOrderCreationDisabled: selectIsOrderCreationDisabled(state),
  userActions: selectUserActions(state),
  hasTradePartnerOrders: selectHasTradePartnerOrders(state),
  hasCreatedTrades: selectHasCreatedTrades(state),
  userIsBFOnly: selectUserHasBFOnly(state),
})

const mapDispatchToProps = {}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('shared')
)(PageLayout)
